<template>
    <div class="qingwu">


        <el-carousel :height="height">

            <div style="display:block;z-index:999999999;position:relative;top:30px;">
                <div class="shop_top_nav">
                    <div class="width_center_1200" >
                        <div class="shop_top_nav_left" @mouseover="subnav_show?subnav:subnav=true"
                             @mouseleave="subnav_show?subnav:subnav=false">
                            LOGO
                            <transition name="el-zoom-in-top">
                                <leftBar :change_color="change_color" v-show="subnav"></leftBar>
                            </transition>
                        </div>
                        <div class="shop_top_nav_center">
                            <ul>
                                <li>
                                    <router-link to="/">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/goods/seckill">Dress</router-link>
                                </li>
                                <li>
                                    <router-link to="/groupbuy/list/keywords.">horse coat</router-link>
                                </li>
                                <li>
                                    <router-link to="/integral/index">garments covering the legs</router-link>
                                </li>
                            </ul>
                        </div>

                        <div class="shop_top_nav_right" >
                            <ul>
                                <li>
                                    <router-link to="/"><img width="22px" height="22px" src="/pc/sousuoxiao.png" alt="搜索"></router-link>
                                </li>
                                <li>
                                    <router-link to="/groupbuy/list/keywords."><img width="23px" height="23px" src="/pc/geren.png" alt="个人中心"></router-link>
                                </li>
                                <li>
                                    <router-link to="/goods/seckill"><img width="24px" height="24px" src="/pc/gouwuche.png" alt="购物车"></router-link>
                                </li>
                            </ul>
                        </div>


                    </div>
                </div>
            </div>

            <el-carousel-item v-for="(v,k) in list" :key="k">
                <div class="shop_banner" :style="'background-position: center;background-image:url('+v.adv_image+');filter:brightness(0.6)'">
                    <router-link :to="v.adv_link||'#'">

                    </router-link>
                </div>
            </el-carousel-item>

        </el-carousel>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {
            list: {
                type: Array,
            },
            height: {
                type: String,
                default: '465px',
            },
        },
        data() {
            return {};
        },
        watch: {},
        computed: {},
        methods: {},
        created() {
        },
        mounted() {
        }
    };
</script>
<style lang="scss" scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .shop_banner {
        height: 100%;
        background-position: center;
    }


    .shop_top_nav {
        height: 40px;
        /*background: #333;*/
        font-size: 14px;
        line-height: 40px;
        color: #000000;

        .shop_top_nav_left {
            float: left;
            width: 240px;
            //background: #ca151e;
            box-sizing: border-box;
            padding-left: 135px;
            position: relative;
            padding:0 auto;
            font-size:32px;
        }

        .shop_top_nav_center {
            padding-left: 20px;
            float: left;
            ul li {
                float: left;
            }
            ul li a {
                line-height: 40px;
                padding: 0 30px;
                color: #fff;
                display: block;
            }
            ul li a:hover {
                // background: #000;
            }
        }

        .shop_top_nav_right {
            padding-right: 78px;
            padding-top:10px;
            float: right;
            ul li {
                float: left;
            }
            ul li a {
                line-height: 30px;
                padding: 0 14px;
                color: #fff;
                display: block;
            }
            ul li a:hover {
               // background: #000;
            }
        }
    }

    .shop_top_seach {
        margin-top: 44px;
        margin-left: 200px;

        ul li {
            float: left;
        }

        .search_input {
            width: 434px;
            height: 38px;
            padding: 10px;
            outline: 0;
            border: 1px solid #efefef;
            border-right: none;
            float: left;
            box-sizing: border-box;
            font-size: 12px;
        }

        .search_button {
            border: 1px solid #efefef;
            float: left;
            width: 38px;
            height: 38px;
            background: #fff;
            outline: 0;
            text-align: center;
        }

        .search_button:hover {
            color: #fff;
            background: #333;
        }

        .index_my_car {
            height: 38px;
            border: 1px solid #efefef;
            margin-left: 30px;
            box-sizing: border-box;
            font-size: 12px;
            line-height: 38px;
            padding: 0 20px;

            span:hover {
                color: #ca151e;
            }

            span {
                margin-right: 20px;
                position: relative;

                i {
                    font-size: 13px;
                    margin-left: 6px;
                }

                a:hover {
                    color: #ca151e;
                }

                .shop_car_dot {
                    position: absolute;
                    top: -24px;
                    line-height: 16px;
                    background: #ca151e;
                    padding: 0 4px;
                    color: #fff;
                    border-radius: 4px;
                    right: -10px;
                }
            }

            span:last-child {
                margin-right: 0;

                i {
                    font-size: 14px;
                }
            }

        }
    }


</style>